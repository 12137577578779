<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "CustomObjects.ValidationRules",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <router-link
        :to="{
          path: '/ProjectDesign/ValidationRules/List',
        }"
        class="btn btn-action-list-page"
      >
        <i class="bi bi-list"></i>
        {{
          $t(
            "ValidationRules.AllData",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </router-link>
      <router-link
        :to="{
          name: 'CustomObjectNewValidationRule',
          params: { customObjectId: this.$route.params.customObjectId },
        }"
        class="btn btn-success btn-action-new-page"
      >
        <i class="bi bi-plus"></i>
        {{
          $t(
            "ValidationRules.NewButton",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </router-link>
    </div>
  </div>
  <Grid :settings="gridSettings" :columns="gridColumns" />
</template>
<script>
export default {
  name: "CustomObjectValidationRules",
  data() {
    return {
      gridSettings: {
        action: "CustomObjectValidationRules",
        requestUrl: String.format("/Lcdp-ValidationRulesByCustomObjectId"),
        requestUrlRouteParamNames: [{ key: "id", value: "customObjectId" }],
        routeRequiredParameters: [
          {
            key: "validationRuleId",
            value: "publicId",
            isRouteParameter: false,
          },
          {
            key: "customObjectId",
            value: "customObjectId",
            isRouteParameter: true,
          },
        ],

        serialNoLink: `#/CustomObject/EditValidationRule/__CUSTOMOBJECTPUBLICID__&validationRuleId=`,

        allowSearchPanel: true,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: false,
        allowDeleting: true,
        deleteActionUrl: "/Lcdp-ValidationDelete",
        autoOrderProcess: true,

        isGatewayRequest: true,

        buttons: [
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "CustomObjectEditValidationRule",
              params: {
                customObjectId: "",
                validationRuleId: "",
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "ValidationRules.ValidationType",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "validationType",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "ValidationRules.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "ValidationRules.Formula",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formula",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "ValidationRules.ErrorMessage",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "errorText",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "ValidationRules.IsShowMessageInModal",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isShowMessageInModal",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
    };
  },
  methods: {},
  mounted() {},
};
</script>
